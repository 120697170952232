import axios from "../services/axiosInterceptor";
import { apiresource, apiMethod, externalBaseUrl } from "../configs/constants";

export const registerUser = (payload) => {
  return axios.post(`${externalBaseUrl}/users/register`, payload);
};

// export const addFeedback = (payload) => {
//   return axios.post(`${externalBaseUrl}/events/add-feedback`, payload);
// };

// export const updateShowEmail = (payload) => {
//   return axios.post(`${externalBaseUrl}/users/update-show-email`, payload);
// };

export const forgotPassword = (payload) => {
  return axios.post(`${externalBaseUrl}/users/forgot-password`, payload);
};

export const resetPassword = (payload) => {
  return axios.post(`${externalBaseUrl}/users/reset-password`, payload);
};

export const addToCart = (payload) => {
  return axios.post(`${externalBaseUrl}/users/add-to-cart`, payload);
};

export const removeFromCart = (payload) => {
  return axios.post(`${externalBaseUrl}/users/remove-cart-item`, payload);
};

export const checkoutCart = (payload) => {
  return axios.post(`${externalBaseUrl}/users/checkout`, payload);
};

export const addAddress = (payload) => {
  return axios.post(`${externalBaseUrl}/users/address-add`, payload);
};

export const updateAddress = (payload) => {
  return axios.post(`${externalBaseUrl}/users/address-update`, payload);
};

export const deleteAddress = (payload) => {
  return axios.post(`${externalBaseUrl}/users/address-update-status`, payload);
};

export const submitContactForm = (payload) => {
  return axios.post(`${externalBaseUrl}/users/contact-create`, payload);
};

export const submitPendingPayment = (payload) => {
  return axios.post(`${externalBaseUrl}/users/pay`, payload);
};
