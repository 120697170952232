import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalState } from "../context/GlobalStateProvider";

// API
import { checkoutCart } from "../ducks/services";
import { getOption } from "../ducks/actions";

// Antd
import { Form, Button, message, Skeleton, Radio } from "antd";

// Components
import CustomInput from "../components/common/CustomInput";
// import CustomAccordion from "../components/ProductItem/CustomAccordion";

// Icons
import { ShoppingOutlined } from "@ant-design/icons";

function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setLoading } = useGlobalState();

  const [pickupValue, setPickupValue] = useState("0");
  const [pickUpLocation, setPickUpLocation] = useState(null);
  const [paymentParams, setPaymentParams] = useState({});
  const [selectedState, setSelectedState] = useState(null);

  const formRef = useRef(null);

  const [form] = Form.useForm();

  const userData = useSelector((state) => state.global.userData);
  const checkoutDropdown = useSelector((state) => state.global.optionList);

  const pickUpLocations = checkoutDropdown?.pickup_locations;
  const cartData = userData?.cart;
  const country = checkoutDropdown?.countries?.find(
    (el) => el?.name === "Malaysia"
  );
  const countryDropdown = checkoutDropdown?.countries
    ?.filter((el) => el?.name === "Malaysia")
    ?.map((item) => ({ label: item?.name, value: item?.name }));

  const statesDropdown = country?.states?.map((el) => ({
    label: el,
    value: el,
  }));

  const totalWeight = cartData?.cart_items?.reduce(
    (acc, el) => acc + el?.weight * el?.qty,
    0
  );

  const totalAmount = cartData?.amount


  useEffect(() => {
    if (pickUpLocations?.length) {
      setPickUpLocation(pickUpLocations[0]?.id);
    }
  }, [pickUpLocations]);


  const handleChangeState = (value) => {
    setSelectedState(value);
  };

  // Form Fields
  const formFields = [
    {
      label: "Country/Region",
      name: "country",
      type: "select",
      required: pickupValue === "0",
      reqMsg: "Please select Country/Region!",
      options: countryDropdown,
      placeholder: "Country/Region",
      hidden: pickupValue === "1",
    },
    {
      label: "First Name",
      name: "first_name",
      type: "text",
      required: pickupValue === "0",
      placeholder: "First Name",
      reqMsg: "Please insert First Name!",
      hidden: pickupValue === "1",
    },
    {
      label: "Last Name",
      name: "last_name",
      type: "text",
      required: pickupValue === "0",
      reqMsg: "Please insert Last Name!",
      placeholder: "Last Name",
      hidden: pickupValue === "1",
    },
    {
      label: "Phone Number",
      name: "recipient_number",
      type: "text",
      required: true,
      reqMsg: "Please insert Phone Number!",
      placeholder: "0123456789",
      hidden: false,
      pattern: /^0\d{9,10}$/,
      patternMsg: "Invalid Phone Number",
    },
    {
      label: "Address",
      name: "address",
      type: "text",
      required: pickupValue === "0",
      reqMsg: "Please insert Address!",
      placeholder: "Address",
      hidden: pickupValue === "1",
    },
    {
      label: "Apartment, suite, etc. (Optional)",
      name: "address2",
      type: "text",
      required: false,
      placeholder: "Apartment, suite, etc. (Optional)",
      hidden: pickupValue === "1",
    },
    {
      label: "Postcode",
      name: "postcode",
      type: "text",
      required: pickupValue === "0",
      reqMsg: "Please insert Postcode!",
      placeholder: "Postcode",
      hidden: pickupValue === "1",
    },
    {
      label: "City",
      name: "city",
      type: "text",
      required: pickupValue === "0",
      reqMsg: "Please insert City!",
      placeholder: "City",
      hidden: pickupValue === "1",
    },
    {
      label: "State/territory",
      name: "state",
      type: "select",
      required: pickupValue === "0",
      reqMsg: "Please select State/territory!",
      options: statesDropdown,
      placeholder: "State/territory",
      hidden: pickupValue === "1",
      onChange: handleChangeState,
    },
    {
      name: "save_address",
      type: "checkbox",
      required: pickupValue === "0",
      title: "Save this address?",
      hidden: pickupValue === "1",
    },
  ];

  useEffect(() => {
    dispatch(getOption());
  }, []);

  useEffect(() => {
    if (userData?.shipping_address) {
      form.setFieldsValue({
        ...userData?.shipping_address,
        save_address:
          userData?.shipping_address?.default_shipping_address === "1",
      });
      setSelectedState(userData?.shipping_address?.state);
    }

    if (cartData?.cart_items?.length === 0) navigate("/", { replace: true });
  }, [userData, cartData]);

  // Submit the form after data is fetched
  useEffect(() => {
    if (paymentParams && "Amount" in paymentParams) {
      // Submit the form programmatically
      formRef.current.submit();
    }
  }, [paymentParams]);

  function calcShippingFees(weight, state) {
    if (!state) return 0;
    state = state.toString().trim().toLowerCase();

    if (state === "sabah" || state === "sarawak") {
      if (weight <= 500) return 20;
      else if (weight > 500 && weight <= 1000) return 27;
      else if (weight > 1000 && weight <= 1500) return 33;
      else if (weight > 1500 && weight <= 2000) return 39;
      else if (weight > 2000 && weight <= 2500) return 46;
      else if (weight > 2500 && weight <= 3000) return 52;
      else if (weight > 3000 && weight <= 3500) return 58;
      else if (weight > 3500 && weight <= 4000) return 65;
      else if (weight > 4000 && weight <= 4500) return 71;
      else if (weight > 4500 && weight <= 5000) return 78;
      else if (weight > 5000 && weight <= 5500) return 84;
      else if (weight > 5500 && weight <= 6000) return 90;
      else if (weight > 6000 && weight <= 6500) return 97;
      else if (weight > 6500 && weight <= 7000) return 103;
      else if (weight > 7000 && weight <= 7500) return 110;
      else if (weight > 7500 && weight <= 8000) return 116;
      else if (weight > 8000 && weight <= 8500) return 122;
      else if (weight > 8500 && weight <= 9000) return 126;
      else if (weight > 9000 && weight <= 9500) return 135;
      else return 142;
    } else {
      if (weight <= 1000) return 12;
      else if (weight > 1000 && weight <= 2000) return 13;
      else if (weight > 2000 && weight <= 3000) return 14;
      else if (weight > 3000 && weight <= 4000) return 17;
      else if (weight > 4000 && weight <= 5000) return 18;
      else if (weight > 5000 && weight <= 6000) return 20;
      else if (weight > 6000 && weight <= 7000) return 21;
      else if (weight > 7000 && weight <= 8000) return 24;
      else if (weight > 8000 && weight <= 9000) return 27;
      else return 30;
    }
  }

  const onFinish = async (val) => {
    if (totalWeight > 10000) {
      message.error(
        "Exceed maximum weight(10kg) per order. Please reduce your quantity."
      );
      return;
    }

    if (Number(totalAmount) > 3000) {
      message.error(
        "Exceed maximum RM3000 per transaction. Please reduce your quantity."
      );
      return;
    }
    setLoading(true);

    let payload;
    if (pickupValue === "1")
      payload = {
        ...val,
        save_address: "0",
        is_pickup: "1",
        location_id: pickUpLocation,
      };
    else
      payload = {
        ...val,
        save_address: val.save_address ? "1" : "0",
        is_pickup: "0",
      };

    await checkoutCart(payload)
      .then((res) => {
        if (res.data.code === 200) {
          message.success("Checkout Successful!");
          setPaymentParams(res.data.data.payment_request);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  return (
    <div className="checkoutPage">
      <div className="border-bottom-1 border-grey m-b-30">
        <div className="container">
          <div className="flex j-c-sb a-i-center p-y-15">
            <Link to="/" className="fs-20 fw-500">
              Monolith Nutrition
            </Link>
            <Link to="/products">
              <ShoppingOutlined />
            </Link>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="checkoutLayout">
          <div className="gridItem deliveryInfo">
            <div className="deliveryForm m-b-50">
              <h2 className="m-b-20">Delivery Options</h2>
              <Radio.Group
                onChange={(e) => setPickupValue(e.target.value)}
                value={pickupValue}
              >
                <Radio value={"0"}>Delivery</Radio>
                <Radio value={"1"}>Pick up</Radio>
              </Radio.Group>

              {pickupValue === "1" && pickUpLocations?.length > 0 && (
                <div className="m-t-50">
                  <p className="font-bold m-b-10">Pick up location</p>
                  <Radio.Group
                    onChange={(e) => setPickUpLocation(e.target.value)}
                    value={pickUpLocation}
                  >
                    {pickUpLocations?.map((el, i) => (
                      <Radio value={el?.id} key={`pickup-loc-${i}`}>
                        {el?.venue}
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
              )}

              <Form
                form={form}
                className="m-t-20"
                layout="vertical"
                name="delivery"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                  save_address: false,
                }}
              >
                {formFields.map((el, i) => (
                  <Fragment key={`delivery-input-${i}`}>
                    <CustomInput item={el} />
                  </Fragment>
                ))}

                <div className="text-right">
                  <button className="btn btn-primary btn-large" type="submit">
                    Submit
                  </button>
                </div>
              </Form>

              {/* Payment form */}
              <form
                ref={formRef}
                method="POST"
                name="ePayment"
                action="https://payment.ipay88.com.my/ePayment/entry.asp"
                style={{ display: "none" }}
              >
                <input name="MerchantCode" value={paymentParams.MerchantCode} />
                <input name="PaymentId" value={paymentParams.PaymentId} />
                <input name="RefNo" value={paymentParams.RefNo} />
                <input name="Amount" value={paymentParams.Amount} />
                <input name="Currency" value={paymentParams.Currency} />
                <input name="ProdDesc" value={paymentParams.ProdDesc} />
                <input name="UserName" value={paymentParams.UserName} />
                <input name="UserEmail" value={paymentParams.UserEmail} />
                <input name="UserContact" value={paymentParams.UserContact} />
                <input name="Remark" value={paymentParams.Remark} />
                <input name="Lang" value={paymentParams.Lang} />
                <input
                  name="SignatureType"
                  value={paymentParams.SignatureType}
                />
                <input name="Signature" value={paymentParams.Signature} />
                <input name="ResponseURL" value={paymentParams.ResponseURL} />
                <input name="BackendURL" value={paymentParams.BackendURL} />
                <button
                  name="button"
                  className="btn btn-lg btn-danger b-30 px-5"
                  type="submit"
                >
                  Pay Now
                </button>
              </form>
            </div>
          </div>

          <div className="gridItem cartInfo">
            {!userData && <Skeleton loading active />}
            {userData && (
              <div className="flex flex-col sticky top-20 gap-30">
                {cartData?.cart_items?.map((el, i) => (
                  <CheckoutItems data={el} key={`checkoutItems-${i}`} />
                ))}

                <div className="flex flex-col gap-10">
                  <div className="flex j-c-sb gap-15">
                    <div className="font-bold">Subtotal</div>
                    <div>RM{cartData?.amount ?? "0.00"}</div>
                  </div>
                  {pickupValue === "0" && (
                    <div className="flex j-c-sb gap-15">
                      <div className="font-bold">
                        Shipping Fees{" "}
                        {cartData?.cart_items?.length > 0 &&
                          `(${totalWeight / 1000} kg)`}
                      </div>
                      <div>
                        RM
                        {calcShippingFees(totalWeight, selectedState)?.toFixed(
                          2
                        )}
                      </div>
                    </div>
                  )}
                  <div className="flex j-c-sb gap-15">
                    <div className="font-bold">Total</div>
                    {pickupValue === "0" && (
                      <div>
                        RM
                        {cartData?.amount
                          ? (
                              Number(cartData?.amount) +
                              calcShippingFees(totalWeight, selectedState)
                            )?.toFixed(2)
                          : "0.00"}
                      </div>
                    )}

                    {pickupValue === "1" && (
                      <div>
                        RM
                        {cartData?.amount ?? "0.00"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function CheckoutItems({ data }) {
  return (
    <div className="flex j-c-sb a-i-center">
      <div className="productItem flex gap-20">
        <div className="productItemImg p-relative">
          <img
            src={data?.product?.featured_graphic_url}
            alt={data?.product?.name}
          />

          <div className="itemQty">{data?.qty}</div>
        </div>
        <div className="productItemName">
          <div className="title m-b-10">
            {data?.product?.name} - {data?.weight}g
          </div>
          <div className="desc paragraph fs-14">
            {data?.product?.description}
          </div>
        </div>
      </div>

      <div className="productPrice">RM{data?.amount}</div>
    </div>
  );
}

export default Checkout;
